<template>
  <div>
    <section class="acct--row">
      <h2>{{ $t("account.account_title") }}</h2>
      <div class="acct--info">
        <div class="acct--info--item">
          <span>{{ $t("account.account_name") }}</span>
          <h4>{{ user.name + " " + user.surname }}</h4>
        </div>
        <div class="acct--info--item">
          <span>{{ $t("account.account_email") }}</span>
          <h4 v-if="user">{{ user.email }}</h4>
        </div>
        <div class="acct--info--item">
          <span>{{ $t("account.account_password") }}</span>
          <h4>*********</h4>
        </div>
      </div>
      <button class="edit edit-trigger" @click="openEditAccountProfileModal">
        {{ $t("account.account_edit_account") }}
      </button>
    </section>
    <editProfileModal
      :user="user"
      :editAccountProfileModalOpened="editAccountProfileModalOpened"
      @closeEditAccountProfileModal="handleCloseEditAccountProfileModal"
    ></editProfileModal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import editProfileModal from "@/components/account/editProfileModal";
export default {
  name: "editAccount",
  components: { editProfileModal },

  props: {
    user: Object
  },

  data() {
    return {
      editAccountProfileModalOpened: false
    };
  },

  methods: {
    ...mapActions("common", ["setPreventScrolling"]),

    openEditAccountProfileModal() {
      this.editAccountProfileModalOpened = !this.editAccountProfileModalOpened;
      this.setPreventScrolling(true);
    },

    handleCloseEditAccountProfileModal(value) {
      this.editAccountProfileModalOpened = value.modalState;
      this.setPreventScrolling(false);
    }
  }
};
</script>

<style lang="scss"></style>
