import { render, staticRenderFns } from "./editProfileModal.vue?vue&type=template&id=0895afa6&scoped=true&lang=html&"
import script from "./editProfileModal.vue?vue&type=script&lang=js&"
export * from "./editProfileModal.vue?vue&type=script&lang=js&"
import style0 from "./editProfileModal.vue?vue&type=style&index=0&id=0895afa6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0895afa6",
  null
  
)

export default component.exports